<template>
    <v-container fluid>
        <v-form @submit.prevent="getApplications('search')">
            <ValidationObserver ref="observer"
                                v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline">{{ $t("search_application") }}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="container_number"
                                                    v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="container_number" :items="containerItems"
                                                    :error="!valid" :error-messages="errors"
                                                    :search-input.sync="containerSearching"
                                                    prepend-icon="mdi-focus-field-horizontal"
                                                    :loading="loadingContainer" :disabled="loadingContainer"
                                                    :no-data-text="containerSearching ? $t('nothing_found_by', {search: containerSearching}) : $t('nothing_found_container')"
                                                    :label="$t('container_number')" @click:clear="containerItems = []"
                                                    item-text="name" color="primary" return-object
                                                    clearable autocomplete="off">
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="car_number" v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="car_number" :items="wagonItems"
                                                    :error="!valid" :error-messages="errors"
                                                    :search-input.sync="wagonSearching"
                                                    item-text="name" item-value="name"
                                                    prepend-icon="mdi-focus-field-horizontal"
                                                    :loading="loadingWagon" :disabled="loadingWagon"
                                                    :no-data-text="wagonSearching ? $t('nothing_found_by', {search: wagonSearching}) : $t('nothing_found_wagon')"
                                                    :label="$t('car_number')" @click:clear="wagonItems = []"
                                                    color="primary" return-object
                                                    clearable autocomplete="off">
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="dateMenuFrom" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition"
                                        offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="dateFrom" name="date_from"
                                                            rules="date_format" v-slot="{ errors, valid }">
                                            <v-text-field v-model="dateFrom"
                                                          :error-messages="errors" :disabled="loading"
                                                          :label="$t('date_operation_from')"
                                                          prepend-icon="mdi-calendar"
                                                          color="primary" readonly v-on="on"
                                                          clearable @click:clear="dateFrom = null">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="dateFrom"
                                                   :max="dateTo ? $moment(dateTo).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                   :locale="lang" @change="dateMenuFrom = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="dateMenuTo" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition"
                                        offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="dateTo" name="date_to"
                                                            rules="date_format" v-slot="{ errors, valid }">
                                            <v-text-field v-model="dateTo"
                                                          :error-messages="errors" :disabled="loading"
                                                          :label="$t('date_operation_to')"
                                                          prepend-icon="mdi-calendar"
                                                          color="primary" readonly v-on="on"
                                                          clearable @click:clear="dateTo = null">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="dateTo"
                                                   :min="dateFrom ? $moment(dateFrom).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                   :locale="lang" @change="dateMenuTo = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-autocomplete v-model="status"
                                                :items="statusItems" :disabled="loadingStatuses"
                                                prepend-icon="mdi-format-list-checks"
                                                item-text="name" item-value="id"
                                                :label="$t('status')" color="primary" clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="client" rules=""
                                                    v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="client" :items="clientItems"
                                                    :error="!valid" :error-messages="errors"
                                                    :search-input.sync="clientSearching"
                                                    item-text="name" item-value="id"
                                                    prepend-icon="mdi-account-supervisor"
                                                    :loading="loadingClients" :disabled="loading"
                                                    :no-data-text="clientSearching ? $t('nothing_found_by', {search: clientSearching}) : $t('nothing_found_client_name')"
                                                    @click="clearClients" :label="$t('client')"
                                                    @click:clear="trackingItems = []"
                                                    color="primary" return-object
                                                    clearable autocomplete="off">
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row v-if="filterShow">
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="application_number"
                                                    rules="min:2|max:255" v-slot="{ errors, valid }">
                                    <v-text-field v-model="application_number" type="text"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-focus-field-horizontal"
                                                  :label="$t('application_number')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="kzh_code" rules="min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="kzh_code" type="text"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-focus-field-horizontal"
                                                  :label="$t('kzh_code')" color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="status" v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="departures" :items="departuresItems"
                                                    :error="!valid" :error-messages="errors"
                                                    :search-input.sync="departuresSearching"
                                                    item-text="name" item-value="id"
                                                    prepend-icon="mdi-focus-field-horizontal"
                                                    :loading="loadingDepartures" :disabled="loadingDepartures"
                                                    :no-data-text="departuresSearching ? $t('nothing_found_by', {search: departuresSearching}) : $t('nothing_found_departures')"
                                                    :label="$t('departures')" @click="clearDepartures"
                                                    @click:clear="departuresItems = []" color="primary"
                                                    return-object clearable autocomplete="off">
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="status" v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="destination" :items="destinationItems"
                                                    :error="!valid" :error-messages="errors"
                                                    :search-input.sync="destinationSearching"
                                                    item-text="name" item-value="id"
                                                    prepend-icon="mdi-focus-field-horizontal"
                                                    :loading="loadingDestination" :disabled="loadingDestination"
                                                    :no-data-text="destinationSearching ? $t('nothing_found_by', {search: destinationSearching}) : $t('nothing_found_destination')"
                                                    :label="$t('destination')" @click="clearDestination"
                                                    @click:clear="destinationItems = []" color="primary"
                                                    return-object clearable autocomplete="off">
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-autocomplete v-model="container_type" :items="containerTypeItems"
                                                :disabled="loadingContainerType"
                                                prepend-icon="mdi-format-list-checks"
                                                item-text="name" item-value="id"
                                                :label="$t('container_type')" color="primary"
                                                clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="car_number_knr" rules="min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="car_number_knr" type="text"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-focus-field-horizontal"
                                                  :label="$t('car_number_knr')" color="primary"
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="dateMenuFromGr" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition"
                                        offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="dateFromGr" name="date_from_gr"
                                                            rules="date_format" v-slot="{ errors, valid }">
                                            <v-text-field v-model="dateFromGr"
                                                          :error-messages="errors" :disabled="loading"
                                                          :label="$t('date_of_arrival_at_the_border_from')"
                                                          prepend-icon="mdi-calendar"
                                                          color="primary" readonly v-on="on"
                                                          clearable @click:clear="dateFromGr = null">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="dateFromGr"
                                                   :max="dateToGr ? $moment(dateToGr).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                   :locale="lang" @change="dateMenuFromGr = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="dateMenuToGr" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition"
                                        offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="dateToGr" name="date_to_gr"
                                                            rules="date_format" v-slot="{ errors, valid }">
                                            <v-text-field v-model="dateToGr"
                                                          :error-messages="errors" :disabled="loading"
                                                          :label="$t('date_of_arrival_at_the_border_to')"
                                                          prepend-icon="mdi-calendar"
                                                          color="primary" readonly v-on="on" clearable
                                                          @click:clear="dateToGr = null">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="dateToGr"
                                                   :min="dateFromGr ? $moment(dateFromGr).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                   :locale="lang" @change="dateMenuToGr = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="dateMenuFromPr" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition"
                                        offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="dateFromPr" name="date_from_pr"
                                                            rules="date_format" v-slot="{ errors, valid }">
                                            <v-text-field v-model="dateFromPr" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-calendar"
                                                          :label="$t('arrival_date_at_destination_from')"
                                                          color="primary" readonly v-on="on" clearable
                                                          @click:clear="dateFromPr = null">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="dateFromPr"
                                                   :max="dateToPr ? $moment(dateToPr).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                   :locale="lang" @change="dateMenuFromPr = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="dateMenuToPr" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition"
                                        offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="dateToPr" name="date_to_pr"
                                                            rules="date_format" v-slot="{ errors, valid }">
                                            <v-text-field v-model="dateToPr" :error-messages="errors"
                                                          :disabled="loading"
                                                          :label="$t('arrival_date_at_destination_to')"
                                                          prepend-icon="mdi-calendar" color="primary"
                                                          readonly v-on="on" clearable
                                                          @click:clear="dateToPr = null">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="dateToPr"
                                                   :min="dateFromPr ? $moment(dateFromPr).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                   :locale="lang" @change="dateMenuToPr = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="menuDateOfDeploymentFrom" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition"
                                        offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="dateOfDeploymentFrom"
                                                            name="date_of_deployment_from" rules="date_format"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="dateOfDeploymentFrom"
                                                          :error-messages="errors" :disabled="loading"
                                                          :label="$t('date_of_deployment_from')"
                                                          prepend-icon="mdi-calendar" color="primary"
                                                          readonly v-on="on" clearable
                                                          @click:clear="dateOfDeploymentFrom = null">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="dateOfDeploymentFrom"
                                                   :max="dateOfDeploymentTo ? $moment(dateOfDeploymentTo).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                   :locale="lang" @change="menuDateOfDeploymentFrom = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="menuDateOfDeploymentTo" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition"
                                        offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="dateOfDeploymentTo" name="date_of_deployment_to"
                                                            rules="date_format" v-slot="{ errors, valid }">
                                            <v-text-field v-model="dateOfDeploymentTo" :error-messages="errors"
                                                          :disabled="loading" :label="$t('date_of_deployment_to')"
                                                          prepend-icon="mdi-calendar" color="primary"
                                                          readonly v-on="on" clearable
                                                          @click:clear="dateOfDeploymentTo = null">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="dateOfDeploymentTo"
                                                   :min="dateOfDeploymentFrom ? $moment(dateOfDeploymentFrom).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                   :locale="lang" @change="menuDateOfDeploymentTo = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-autocomplete v-model="delivered" :items="deliveredItems"
                                                :disabled="loadingDelivered"
                                                prepend-icon="mdi-format-list-checks"
                                                item-text="name" item-value="id"
                                                :label="$t('delivered')" color="primary"
                                                clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-select :value="perPage" :items="perPageItems"
                                          :disabled="loading" :label="$t('items_per_page')"
                                          prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-right" v-if="!filterShow">
                                <span @click="filterShow = true" class="blue--text cursor-pointer">
                                    {{ $t('advanced_search') }}
                                </span>
                            </v-col>
                            <v-col cols="12" class="text-right" v-if="filterShow">
                                <span @click="filterShow = false" class="blue--text cursor-pointer">
                                    {{ $t('hide') }}
                                </span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pt-10 px-0">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-btn :block="$vuetify.breakpoint.xsOnly"
                                       color="primary" @click="UploadApplications">
                                    <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                                    {{ $t("save_to_excel") }}
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="6" class="text-right">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t("search") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat light>
            <v-card-text class="px-0">
                <v-data-table :headers="headers" :items="applicationItems" :options.sync="options"
                              :page.sync="page" :items-per-page="perPage"
                              :server-items-length="totalApplications"
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir"
                              :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')"
                              class="elevation-1" hide-default-footer>
                    <template v-slot:item.application_date="{ item }">
                        {{ item.application_date ? $moment(item.application_date).format("YYYY-MM-DD") : "" }}
                    </template>
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                            {{ item.deleted ? " (" + $t("deleted") + ")" : "" }}
                        </div>
                    </template>
                    <template v-slot:item.paid="{ item }">
                        {{ item.paid ? $t("yes") : $t("no") }}
                    </template>
                    <template v-slot:item.date_of_arrival_at_the_border="{ item }">
                        {{
                            item.date_of_arrival_at_the_border ? $moment(item.date_of_arrival_at_the_border).format("YYYY-MM-DD") : ""
                        }}
                    </template>
                    <template v-slot:item.arrival_date_at_destination="{ item }">
                        {{
                            item.arrival_date_at_destination ? $moment(item.arrival_date_at_destination).format("YYYY-MM-DD") : ""
                        }}
                    </template>
                    <template v-slot:item.date_operation="{ item }">
                        {{ item.date_operation ? $moment(item.date_operation).format("YYYY-MM-DD") : "" }}
                    </template>
                    <template v-slot:item.date_of_deployment="{ item }">
                        {{ item.date_of_deployment ? $moment(item.date_of_deployment).format("YYYY-MM-DD") : "" }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" class="mr-2"
                                       @click="showDetailsApplication(item)">
                                    <v-icon>mdi-card-text-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t("details") }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-show="totalApplications > perPage"
                              v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading">
                </v-pagination>
            </v-card-actions>
        </v-card>
        <v-dialog v-if="dialogDetails" v-model="dialogDetails"
                  fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>{{ $t("details") }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="cancelDetails()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-row class="pt-4">
                        <v-col cols="12" sm="6">
                            <v-col cols="12" class="py-1 text-right">
                                <b> Документ в 1С:</b>
                                {{ detailsItems.uuid ? detailsItems.uuid : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> Клиент:</b>
                                {{
                                    detailsItems.client.name ? detailsItems.client.name + " ( " + detailsItems.client.login + " )" : $t("no_data")
                                }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> Номер заявки:</b>
                                {{ detailsItems.application_number ? detailsItems.application_number : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> Дата заявки:</b>
                                {{ detailsItems.application_date ? $moment(detailsItems.application_date).format("YYYY-MM-DD HH:MM") : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> Дата операции:</b>
                                {{
                                    detailsItems.date_operation ? $moment(detailsItems.date_operation).format("YYYY-MM-DD HH:MM") : $t("no_data")
                                }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> Код груза:</b>
                                {{ detailsItems.kzh_code ? detailsItems.kzh_code : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> Станция отправления:</b>
                                {{ detailsItems.departures ? detailsItems.departures : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> Дата прибытия на границу:</b>
                                {{
                                    detailsItems.date_of_arrival_at_the_border ? $moment(detailsItems.date_of_arrival_at_the_border).format("YYYY-MM-DD HH:MM") : $t("no_data")
                                }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> Станция назначения:</b>
                                {{ detailsItems.destination ? detailsItems.destination : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> Номер контейнера:</b>
                                {{ detailsItems.container_number ? detailsItems.container_number : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> Тип контейнера:</b>
                                {{ detailsItems.container_type ? detailsItems.container_type : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> Номер накладной:</b>
                                {{ detailsItems.dispatch_number ? detailsItems.dispatch_number : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1 text-right">
                                <b> Дата перегруза:</b>
                                {{
                                    detailsItems.overload_date ? $moment(detailsItems.overload_date).format("YYYY-MM-DD HH:MM") : $t("no_data")
                                }}
                            </v-col>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-col cols="12" class="py-1">
                                <b> Номер вагона:</b>
                                {{ detailsItems.car_number ? detailsItems.car_number : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> Номер вагона КНР:</b>
                                {{ detailsItems.car_number_knr ? detailsItems.car_number_knr : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> Станция операции:</b>
                                {{ detailsItems.operations ? detailsItems.operations : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> Статус:</b>
                                {{ detailsItems.status ? detailsItems.status : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> Дата прибытия на станцию назначения:</b>
                                {{
                                    detailsItems.arrival_date_at_destination ? $moment(detailsItems.arrival_date_at_destination).format("YYYY-MM-DD HH:MM") : $t("no_data")
                                }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> Расстояние до станцию назначения:</b>
                                {{
                                    detailsItems.distance_to_destination ? detailsItems.distance_to_destination : $t("no_data")
                                }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> Вес груза:</b>
                                {{
                                    detailsItems.weight && detailsItems.weight > 0 ? detailsItems.weight : $t("no_data")
                                }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> ЕТ СНГ код груза:</b>
                                {{ detailsItems.etsng ? detailsItems.etsng : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> Состояние:</b>
                                {{ detailsItems.condition ? detailsItems.condition : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> Доставлен:</b>
                                {{ detailsItems.paid === true ? $t("yes") : $t("no") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> Подкод:</b>
                                {{ detailsItems.subcode.subcode1 ? detailsItems.subcode.subcode1 : $t("no_data") }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> Дата отправки:</b>
                                {{
                                    detailsItems.date_sending ? $moment(detailsItems.date_sending).format("YYYY-MM-DD HH:MM") : $t("no_data")
                                }}
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <b> Дата отправки по СНГ:</b>
                                {{
                                    detailsItems.date_sending_sng ? $moment(detailsItems.date_sending_sng).format("YYYY-MM-DD HH:MM") : $t("no_data")
                                }}
                            </v-col>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="2"></v-col>
                        <v-col class="py-0" cols="12" sm="8">
                            <template>
                                <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
                                    <v-timeline-item v-for="(item, i) in detailsItems.application_operations"
                                                     :key="i" color="primary" icon="mdi-chevron-down-circle-outline"
                                                     fill-dot>
                                        <v-card color="primary" dark>
                                            <v-card-title class="title">
                                                Дата обновления:
                                                {{
                                                    item.date_of_deployment ? $moment(item.date_of_deployment).format("YYYY-MM-DD HH:MM") : $t("no_data")
                                                }}
                                            </v-card-title>
                                            <v-card-text class="white text--primary">
                                                <p style="line-height: 25px">
                                                    <b>Номер вагона:</b>
                                                    {{ item.car_number ? item.car_number : $t("no_data") }}
                                                    <br/>
                                                    <b>Расстояние до станцию назначения:</b>
                                                    {{
                                                        item.distance_to_destination ? item.distance_to_destination : $t("no_data")
                                                    }}
                                                    <br/>
                                                    <b>Статус:</b>
                                                    {{ item.status ? item.status : $t("no_data") }}
                                                    <br/>
                                                    <b>Станция операции:</b>
                                                    {{ item.operations ? item.operations : $t("no_data") }}
                                                    <br/>
                                                    Дата операции:
                                                    {{
                                                        item.date_operation ? $moment(item.date_operation).format("YYYY-MM-DD HH:MM") : $t("no_data")
                                                    }}
                                                </p>
                                            </v-card-text>
                                        </v-card>
                                    </v-timeline-item>
                                </v-timeline>
                            </template>
                        </v-col>
                        <v-col cols="12" sm="2"></v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {mapGetters} from "vuex";
import debounce from "lodash/debounce";

export default {
    name: "Applications",
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            sortBy: "id",
            sortDir: false,
            loading: false,
            dialogDetails: false,
            filterShow: false,
            detailsItems: [],
            dateFrom: null,
            dateMenuFrom: false,
            dateTo: null,
            dateMenuTo: false,

            dateFromGr: null,
            dateMenuFromGr: false,
            dateToGr: null,
            dateMenuToGr: false,

            dateFromPr: null,
            dateMenuFromPr: false,
            dateToPr: null,
            dateMenuToPr: false,

            dateOfDeploymentFrom: null,
            menuDateOfDeploymentFrom: false,
            dateOfDeploymentTo: null,
            menuDateOfDeploymentTo: false,

            application: null,
            application_number: null,
            kzh_code: null,
            container_number: null,
            car_number: null,
            car_number_knr: null,
            options: {},
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalApplications: 0,
            loadingStatuses: false,
            status: null,
            statusItems: [],
            container_type: null,
            containerTypeItems: [],
            loadingContainerType: null,
            departures: null,
            departuresItems: [],
            departuresSearching: null,
            loadingDepartures: false,
            loadingContainer: false,
            containerItems: [],
            containerSearching: null,
            wagonItems: [],
            loadingWagon: false,
            wagonSearching: null,
            destination: null,
            destinationItems: [],
            destinationSearching: null,
            loadingDestination: false,
            loadingDelivered: false,
            delivered: null,
            client: null,
            clientItems: [],
            loadingClients: false,
            clientSearching: null,
            deliveredItems: [
                {
                    id: -1,
                    name: this.$t("no"),
                },
                {
                    id: 1,
                    name: this.$t("yes"),
                },
            ],
            applicationItems: [],
            headers: [
                {
                    text: this.$t("date_of_deployment"),
                    align: "center",
                    sortable: true,
                    value: "date_of_deployment",
                },
                {
                    text: this.$t("car_number"),
                    align: "center",
                    sortable: true,
                    value: "car_number",
                },
                {
                    text: this.$t("application_date"),
                    align: "center",
                    sortable: true,
                    value: "application_date",
                },
                {
                    text: this.$t("application_number"),
                    align: "center",
                    sortable: true,
                    value: "application_number",
                },
                {
                    text: this.$t("kzh_code"),
                    align: "center",
                    sortable: true,
                    value: "kzh_code",
                },
                {
                    text: this.$t("departures"),
                    align: "center",
                    sortable: false,
                    value: "departures",
                },
                {
                    text: this.$t("destination"),
                    align: "center",
                    sortable: false,
                    value: "destination",
                },
                {
                    text: this.$t("date_of_arrival_at_the_border"),
                    align: "center",
                    sortable: true,
                    value: "date_of_arrival_at_the_border",
                },
                {
                    text: this.$t("container_number"),
                    align: "center",
                    sortable: true,
                    value: "container_number",
                },
                {
                    text: this.$t("container_type"),
                    align: "center",
                    sortable: false,
                    value: "container_type",
                },
                {
                    text: this.$t("dispatch_number"),
                    align: "center",
                    sortable: true,
                    value: "dispatch_number",
                },
                {
                    text: this.$t("car_number_knr"),
                    align: "center",
                    sortable: true,
                    value: "car_number_knr",
                },
                {
                    text: this.$t("operations"),
                    align: "center",
                    sortable: false,
                    value: "operations",
                },
                {
                    text: this.$t("status"),
                    align: "center",
                    sortable: false,
                    value: "status",
                },
                {
                    text: this.$t("arrival_date_at_destination"),
                    align: "center",
                    sortable: true,
                    value: "arrival_date_at_destination",
                },
                {
                    text: this.$t("date_operation"),
                    align: "center",
                    sortable: true,
                    value: "date_operation",
                },
                {
                    text: this.$t("details"),
                    align: "center",
                    value: "action",
                    sortable: false,
                    width: 120,
                },
            ],

            items: [
                {
                    color: "red lighten-2",
                    icon: "mdi-star",
                },
                {
                    color: "purple darken-1",
                    icon: "mdi-book-variant",
                },
                {
                    color: "green lighten-1",
                    icon: "mdi-airballoon",
                },
                {
                    color: "indigo",
                    icon: "mdi-buffer",
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["lang", "itemsPerPage", "perPageItems"]),
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage;
        this.getStatus();
        this.getContainerType();
    },
    watch: {
        options: {
            handler() {
                this.getApplications();
            },
            deep: false,
        },
        clientSearching: debounce(function (val) {
            // if (val && !this.client) {
            this.getClients(val);
            // }
        }, 500),
        containerSearching: debounce(function (val) {
            if (val && !this.container_number) {
                this.getContainers(val);
            }
        }, 500),
        wagonSearching: debounce(function (val) {
            if (val && !this.car_number) {
                this.getWagons(val);
            }
        }, 500),
        departuresSearching: debounce(function (val) {
            if (val && !this.departures) {
                this.getDepartures(val);
            }
        }, 500),
        destinationSearching: debounce(function (val) {
            if (val && !this.destination) {
                this.getDestination(val);
            }
        }, 500),
    },
    methods: {
        clearClients() {
            if (!this.client) {
                this.clientItems = [];
            }
            this.tracking = null;
            this.trackingItems = [];
        },
        async getClients(str) {
            if (str) {
                this.loadingClients = true;
                let params = {};
                params.filter = "search";
                if (str !== "undefined") {
                    params.client = str;
                }
                await this.$http
                    .get("admin/client", {
                        params: params,
                    })
                    .then((res) => {
                        this.clientItems = res.body.data;
                    })
                    .catch((err) => {
                        this.clientItems = [];
                        this.$toastr.error(
                            this.$t("failed_to_get_list_clients")
                        );
                    })
                    .finally((end) => {
                        this.loadingClients = false;
                    });
            }
        },
        async showDetailsApplication(item) {
            this.loading = true;
            await this.$http
                .get("admin/application/" + item.id)
                .then((res) => {
                    this.detailsItems = res?.body.data;
                    this.dialogDetails = true;
                })
                .catch((err) => {
                    this.applicationItems = [];
                    this.$toastr.error(
                        this.$t("failed_to_get_list_applications")
                    );
                })
                .finally((end) => {
                    this.loading = false;
                });
        },
        async getStatus() {
            this.loadingStatuses = true;
            let params = {};
            params.filter = "search";

            await this.$http
                .get("admin/status", {
                    params: params,
                })
                .then((res) => {
                    this.statusItems = res.body.data;
                })
                .catch((err) => {
                    this.statusItems = [];
                    this.$toastr.error(this.$t("failed_to_get_status"));
                })
                .finally((end) => {
                    this.loadingStatuses = false;
                });
        },
        async getContainerType() {
            this.loadingContainerType = true;
            let params = {};
            params.filter = "search";

            await this.$http
                .get("admin/container_type", {
                    params: params,
                })
                .then((res) => {
                    this.containerTypeItems = res.body.data;
                })
                .catch((err) => {
                    this.containerTypeItems = [];
                    this.$toastr.error(this.$t("failed_to_get_container_type"));
                })
                .finally((end) => {
                    this.loadingContainerType = false;
                });
        },
        clearDepartures() {
            if (!this.departures) {
                this.departuresItems = [];
            }
        },
        async getDepartures(str) {
            this.loadingDepartures = true;
            let params = {};
            params.filter = "search";
            if (str !== "undefined") {
                params.station_name = str;
            }

            await this.$http
                .get("admin/station", {
                    params: params,
                })
                .then((res) => {
                    this.departuresItems = res.body.data;
                })
                .catch((err) => {
                    this.departuresItems = [];
                    this.$toastr.error(this.$t("failed_to_get_station"));
                })
                .finally((end) => {
                    this.loadingDepartures = false;
                });
        },
        clearDestination() {
            if (!this.destination) {
                this.destinationItems = [];
            }
        },
        async getDestination(str) {
            this.loadingDestination = true;
            let params = {};
            params.filter = "search";
            if (str !== "undefined") {
                params.station_name = str;
            }

            await this.$http
                .get("admin/station", {
                    params: params,
                })
                .then((res) => {
                    this.destinationItems = res.body.data;
                })
                .catch((err) => {
                    this.destinationItems = [];
                    this.$toastr.error(this.$t("failed_to_get_station"));
                })
                .finally((end) => {
                    this.loadingDestination = false;
                });
        },
        async getWagons(str) {
            this.loadingWagon = true;
            let params = {};
            params.filter = "search";
            if (str !== "undefined") {
                params.container = str;
            }

            await this.$http
                .get("admin/wagon", {
                    params: params,
                })
                .then((res) => {
                    this.wagonItems = res.body.data;
                })
                .catch((err) => {
                    this.wagonItems = [];
                    this.$toastr.error(this.$t("failed_to_get_wagons"));
                })
                .finally((end) => {
                    this.loadingWagon = false;
                });
        },
        async getContainers(str) {
            this.loadingContainer = true;
            let params = {};
            params.filter = "search";
            if (str !== "undefined") {
                params.container = str;
            }

            await this.$http
                .get("admin/container", {
                    params: params,
                })
                .then((res) => {
                    this.containerItems = res.body.data;
                })
                .catch((err) => {
                    this.containerItems = [];
                    this.$toastr.error(this.$t("failed_to_get_containers"));
                })
                .finally((end) => {
                    this.loadingContainer = false;
                });
        },
        async UploadApplications() {
            this.loading = true;
            const {sortBy, sortDesc, page, itemsPerPage} = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? "asc" : "desc";
            }
            if (page !== undefined) {
                params.page = page;
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }
            params.upload = 1;
            if (
                this.client !== undefined &&
                this.client != null &&
                this.client !== ""
            ) {
                params.client_id = this.client.id;
            }

            if (
                this.dateFrom !== undefined &&
                this.dateFrom != null &&
                this.dateFrom !== ""
            ) {
                params.dateFrom = this.dateFrom;
            }
            if (
                this.dateTo !== undefined &&
                this.dateTo != null &&
                this.dateTo !== ""
            ) {
                params.dateTo = this.dateTo;
            }

            if (
                this.dateFromGr !== undefined &&
                this.dateFromGr != null &&
                this.dateFromGr !== ""
            ) {
                params.date_of_arrival_at_the_border_from = this.dateFromGr;
            }
            if (
                this.dateToGr !== undefined &&
                this.dateToGr != null &&
                this.dateToGr !== ""
            ) {
                params.date_of_arrival_at_the_border_to = this.dateToGr;
            }

            if (
                this.dateFromPr !== undefined &&
                this.dateFromPr != null &&
                this.dateFromPr !== ""
            ) {
                params.arrival_date_at_destination_from = this.dateFromPr;
            }
            if (
                this.dateToPr !== undefined &&
                this.dateToPr != null &&
                this.dateToPr !== ""
            ) {
                params.arrival_date_at_destination_to = this.dateToPr;
            }
            if (this.delivered) {
                if (this.delivered.id) {
                    params.delivered = this.delivered.id;
                } else {
                    params.delivered = this.delivered;
                }
            }
            if (this.status) {
                if (this.status.id) {
                    params.status = this.status.id;
                } else {
                    params.status = this.status;
                }
            }
            if (this.car_number_knr) {
                params.car_number_knr = this.car_number_knr;
            }
            if (this.car_number) {
                if (this.car_number.id) {
                    params.car_number = this.car_number.name;
                }
            }
            if (this.container_type) {
                if (this.container_type.id) {
                    params.container_type = this.container_type.id;
                } else {
                    params.container_type = this.container_type;
                }
            }
            if (this.container_number) {
                if (this.container_number.id) {
                    params.container_number = this.container_number.name;
                }
            }
            if (this.destination) {
                if (this.destination.id) {
                    params.destination = this.destination.id;
                } else {
                    params.destination = this.destination;
                }
            }
            if (this.departures) {
                if (this.departures.id) {
                    params.departures = this.departures.id;
                } else {
                    params.departures = this.departures;
                }
            }
            if (this.kzh_code) {
                params.kzh_code = this.kzh_code;
            }
            if (this.application_number) {
                params.application_number = this.application_number;
            }

            await this.$http
                .get("admin/application", {
                    params: params,
                })
                .then((res) => {
                    this.saveToFile(res.body.data.file);
                })
                .catch((err) => {
                    this.$toastr.error(this.$t("failed_to_upload_file"));
                })
                .finally((end) => {
                    this.loading = false;
                });
        },
        async getApplications(type) {
            this.loading = true;
            const {sortBy, sortDesc, page, itemsPerPage} = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? "asc" : "desc";
            }
            if (page !== undefined) {
                params.page = page;
            }
            if (type === 'search') {
                params.page = 1
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }

            if (
                this.client !== undefined &&
                this.client != null &&
                this.client !== ""
            ) {
                params.client_id = this.client.id;
            }

            if (
                this.dateFrom !== undefined &&
                this.dateFrom != null &&
                this.dateFrom !== ""
            ) {
                params.dateFrom = this.dateFrom;
            }
            if (
                this.dateTo !== undefined &&
                this.dateTo != null &&
                this.dateTo !== ""
            ) {
                params.dateTo = this.dateTo;
            }

            if (
                this.dateFromGr !== undefined &&
                this.dateFromGr != null &&
                this.dateFromGr !== ""
            ) {
                params.date_of_arrival_at_the_border_from = this.dateFromGr;
            }
            if (
                this.dateToGr !== undefined &&
                this.dateToGr != null &&
                this.dateToGr !== ""
            ) {
                params.date_of_arrival_at_the_border_to = this.dateToGr;
            }

            if (
                this.dateFromPr !== undefined &&
                this.dateFromPr != null &&
                this.dateFromPr !== ""
            ) {
                params.arrival_date_at_destination_from = this.dateFromPr;
            }
            if (
                this.dateToPr !== undefined &&
                this.dateToPr != null &&
                this.dateToPr !== ""
            ) {
                params.arrival_date_at_destination_to = this.dateToPr;
            }

            if (
                this.dateOfDeploymentFrom !== undefined &&
                this.dateOfDeploymentFrom != null &&
                this.dateOfDeploymentFrom !== ""
            ) {
                params.date_of_deployment_from = this.dateOfDeploymentFrom;
            }
            if (
                this.dateOfDeploymentTo !== undefined &&
                this.dateOfDeploymentTo != null &&
                this.dateOfDeploymentTo !== ""
            ) {
                params.date_of_deployment_to = this.dateOfDeploymentTo;
            }

            if (this.delivered) {
                if (this.delivered.id) {
                    params.delivered = this.delivered.id;
                } else {
                    params.delivered = this.delivered;
                }
            }
            if (this.status) {
                if (this.status.id) {
                    params.status = this.status.id;
                } else {
                    params.status = this.status;
                }
            }

            if (this.car_number_knr) {
                params.car_number_knr = this.car_number_knr;
            }

            if (this.car_number) {
                if (this.car_number.id) {
                    params.car_number = this.car_number.name;
                }
            }

            if (this.container_type) {
                if (this.container_type.id) {
                    params.container_type = this.container_type.id;
                } else {
                    params.container_type = this.container_type;
                }
            }

            if (this.container_number) {
                if (this.container_number.id) {
                    params.container_number = this.container_number.name;
                }
            }

            if (this.destination) {
                if (this.destination.id) {
                    params.destination = this.destination.id;
                } else {
                    params.destination = this.destination;
                }
            }

            if (this.departures) {
                if (this.departures.id) {
                    params.departures = this.departures.id;
                } else {
                    params.departures = this.departures;
                }
            }
            if (this.kzh_code) {
                params.kzh_code = this.kzh_code;
            }
            if (this.application_number) {
                params.application_number = this.application_number;
            }

            await this.$http
                .get("admin/application", {
                    params: params,
                })
                .then((res) => {
                    this.applicationItems = res.body.data;
                    this.page = res.body.meta.current_page;
                    this.totalApplications = res.body.meta.total;
                    this.pageCount = res.body.meta.last_page;
                })
                .catch((err) => {
                    this.applicationItems = [];
                    this.totalApplications = 0;
                    this.$toastr.error(
                        this.$t("failed_to_get_list_applications")
                    );
                })
                .finally((end) => {
                    this.loading = false;
                });
        },
        cancelDetails() {
            this.dialogDetails = false;
        },
        saveToFile(url) {
            let link = document.createElement("a");
            link.setAttribute("href", url);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    },
};
</script>
